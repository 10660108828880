import React, { Component } from 'react';


class About extends Component {
    render() {
        return (
            <div className="condiv">
            <h1 className="subtopic">About Us</h1>
            <div className="hr_line line2 glass"><div className="inner2"></div></div>
            <h2 className="abouthead">Cipher <mark className="mark2">Garden</mark></h2>
            {/* <p>Web 3 Buidler</p> */}
            <br></br>
            <p className="p10">We are a decentralized organization specializing in blockchain gaming and p2e economies.
            </p>
            {/* <div className="Edu">
                <div class="back1 back2"><i class="fa fa-book i1 i3"></i></div>
            </div> */}
            {/* <h3>Education</h3>
            <div className="vr_line glass">
                <div className="vr_inner inn1"></div>
                <div className="vr_inner inn2"></div>
            </div>
            <h4>B.S. who cares</h4>
            <p className="p1">hogwartz</p>
            <p className="p1"><i class="fa fa-calendar i2"></i>August 2017 - present</p>
            <h4>High School</h4>
            <p className="p1">Our Fair Lady of Peace, </p>
            <p className="p1"><i class="fa fa-calendar i2"></i>May 2017</p>

            <h3 class="sk_head">Apps</h3>
            <div class="sk glass">HTML</div>
            <div class="sk s2 glass">CSS</div>
            <div class="sk s3 glass">JavaScript</div>
            <div class="sk s4 glass">React</div>
            <div class="sk s5 glass">Node</div>
            <div class="sk s6 glass">Solidity</div> */}
            </div>
            )
        }
    }
    
export default About