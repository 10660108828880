import React, { Component } from 'react';


class Shift extends Component {
    render() {
        return (
                <div className='condiv home'>
                <iframe src='https://docs.google.com/forms/d/e/1FAIpQLSeuJpCdeqaR36wkBIDnY_7esdGqEGJ-Am8jl1G1fR6R17ddew/viewform?embedded=tru' width='640' height='500' frameborder='0' marginheight='0' marginwidth='0'>Loading…</iframe>
                </div>
            )
        }
    }
    
export default Shift


// class Contact extends Component {
//     render() {
//         return (
//             <div className="condiv contact">
//             {/* <h1 className="subtopic">Contact Me</h1>
//             <div className="hr_line line2 glass"><div className="inner2 inner3"></div></div>
//             <div class="con_out glass"><img src={Map} class="co1"></img></div>
//             <a href="#"><div className="back3 glass">Download Resume</div></a>
//             <a href="mailto:youremail@gmail.com"><div className="back3 back4 glass">Contact Me</div></a>
//             <div className="hr_line line3 glass"><div className="inner3"></div></div> */}
//             <Social />
//             </div>
//             )
//         }
//     }
    
//     export default Contact