import React, { Component } from 'react';
import Navitem from './Navitem';
import profilepic from '../img/profile_photo.png';

class Navbar extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            'NavItemActive':''
        }
    }
    activeitem=(x)=>
    {
        if(this.state.NavItemActive.length>0){
            document.getElementById(this.state.NavItemActive).classList.remove('active');
        }
        this.setState({'NavItemActive':x},()=>{
            document.getElementById(this.state.NavItemActive).classList.add('active');
        });
    };
    render() {
        return (
            <nav className="glass">
            <img src={profilepic} className="profilepic"></img>
            <h2>Cipher <mark>Garden</mark></h2>
            <p>A Decentralized Organization</p>
            <ul>
            <Navitem item="Home" tolink="/"  activec={this.activeitem}></Navitem>
            {/* <Navitem item="About" toLink="/about" activec={this.activeitem}></Navitem> */}
            <Navitem item="Shift" tolink="/shift"  activec={this.activeitem}></Navitem>
            {/* <Navitem item="Portfolio" tolink="/portfolio"  activec={this.activeitem}></Navitem> */}
            {/* <Navitem item="Contact" tolink="/contact"  activec={this.activeitem}></Navitem> */}
            </ul>
            </nav>
            )
        }
    }
    
    export default Navbar