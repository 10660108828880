import React, {useState} from 'react';
import {ThemeProvider, createGlobalStyle, css} from 'styled-components';
import { BsSunFill, BsMoonFill} from 'react-icons/bs';
import { GiSprout, GiFlamingo } from 'react-icons/gi';
import { BiCoffee } from 'react-icons/bi';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './contents/Home';
import About from './contents/About';
import Portfolio from './contents/Portfolio';
import Contact from './contents/Contact';
import Shift from './contents/Shift';
import theme from 'styled-theming';
import { render } from 'react-dom';

// const GlobalStyle = createGlobalStyle`
//   body{
//   background: linear-gradient(${props => props.theme.mode === 'dark' ? '#868f96, #596164' : '(#ff758c,#764ba2'});
//   }
// `

// const GlobalStyle = theme[`${props => props.theme.mode}`]


const GlobalStyle = createGlobalStyle`
  body{
    ${props => myThemes[props.theme.mode] || myThemes['dark']}
  }
`


const darkTheme = css`
  background: #000000;
`

const darkIcon = () => {
  return <BsMoonFill class="i1"/>
}

const coffeeTheme = css`
  background: #FFDEA6;
`

const coffeeIcon = () => {
  return <BiCoffee class="i1"/>
}

const sproutTheme = css`
background: linear-gradient(#868f96, #596164);
`
const sproutIcon = () => {
  return <GiSprout class="i1"/>
}


const miamiTheme = css`
background: linear-gradient(#ff758c,#764ba2);
`

const miamiIcon = () => {
  return <GiFlamingo class="i1"/>
}

const lightTheme = css`
background: #FFFFFF;
`

const lightIcon = () => {
  return <BsSunFill class="i1"/> 
}


const myThemes = {
  'miami': miamiTheme,
  'sprout': sproutTheme,
  'coffee': coffeeTheme,
  'dark': darkTheme,
  'light': lightTheme
}

const myIcons = {
  'miami': miamiIcon(),
  'sprout': sproutIcon(),
  'coffee': coffeeIcon(),
  'dark': darkIcon(),
  'light': lightIcon()
}

const iterateThemes = (current) => {
  let themeKeys = Object.keys(myThemes);
  let nextIndex = themeKeys.indexOf(current) +1;
  let nextItem = themeKeys[nextIndex];
  let firstItem = themeKeys[0];
  if (myThemes[nextItem]) {
    return {mode: nextItem}
  } else {
    return {mode: firstItem};
  }
}


function App() {
  const [theme, setTheme] = useState({ mode: 'miami'});
  return (
    <ThemeProvider theme={theme}>
      <>
    <GlobalStyle />
    <Router>
    <div className="App">
    <Navbar />
    <Route exact path="/">
    <Home />
    </Route>
    <Route path="/about">
    <About />
    </Route>
    <Route path="/portfolio">
    <Portfolio />
    </Route>
    <Route path="/contact">
    <Contact />
    </Route>
    <Route path="/shift">
    <Shift />
    </Route>



      <div class="back1 glass" onClick={e => setTheme(theme.mode = iterateThemes(theme.mode))}>
        <div>
        {myIcons[theme.mode]}
        </div>
      </div>

    </div>
    </Router>
    </>
    </ThemeProvider>
    );
  }
  
  export default App;